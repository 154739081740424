import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

class MainBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-three">
            <div className="creative-banner-two"></div>
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h4>We excel in crafting <span>solutions</span> that</h4>
                                        <h4>propel you past your existing <span><ReactTypingEffect
                                                text={['technology', 'cloud', 'web', 'automation']}
                                                speed={300}
                                                eraseDelay={150}
                                            /></span> capabilities.</h4> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;