import React from 'react';
import aboutImg from '../../assets/images/about-strategy-2.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>About <span>Us</span></h2>                        
                        <p>We are innovating today for a smarter tomorrow, focusing on small and medium-sized business solutions that go beyond the usual. Our goal is to empower your business, enabling you to reach new technological heights and excel in your unique market.</p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-5">
                        <img src={aboutImg} alt="about-img" />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>We specialize in designing cost-effective and tailored <span>enterprise solutions</span>, drawing on our hands-on experience across various industries and specialties. Our approach is focused on delivering personalized, budget-friendly solutions that meet the unique needs of each business.:</h2>
                                </div>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Solution Modernization</li>
                                    <li><i className="fa fa-check"></i>Cloud Migrations</li>
                                    <li><i className="fa fa-check"></i>Cloud Usage Optimization</li>
                                    <li><i className="fa fa-check"></i>Product Development</li>
                                    <li><i className="fa fa-check"></i>Process Automations</li>
                                </ul>

                                <ul>
                                    <li><i className="fa fa-check"></i>Software Integrations</li>
                                    <li><i className="fa fa-check"></i>Data Aggregations</li>
                                    <li><i className="fa fa-check"></i>Custom UIs</li>
                                    <li><i className="fa fa-check"></i>Data Insights</li>
                                    <li><i className="fa fa-check"></i>APIs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;