import React from 'react';
import imgOne from '../../assets/images/services-img1.jpg';
import imgThree from '../../assets/images/services-img3.jpg';
import imgFour from '../../assets/images/services-img4.jpg';
import imgFive from '../../assets/images/services-img5.jpg';
import imgNine from '../../assets/images/services-img9.jpg';
 
class Services extends React.Component {
    render(){
        return (
            <section id="services" className="services-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Our <span>Services</span></h2>
                        <p>We offer a collaborative partnership that supports you from the initial spark of an idea all the way to the successful deployment of the final product.</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgOne} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-pencil-square-o"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>Solution Design</h3>
                                    <p>Expertly transforming your unique needs into a scalable solution, tailored to evolve and grow with your business aspirations.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgFive} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-desktop"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>Development</h3>
                                    <p>Embracing cutting-edge standards and a versatile approach to multiple programming languages for robust solution creation.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgThree} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-anchor"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>Implementation</h3>
                                    <p>Guaranteeing a seamless and successful deployment of the solution in your production environment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgFour} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-line-chart"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>Analytics</h3>
                                    <p>Offering comprehensive analytical tools to furnish workers at all levels with deep insights into the data.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgNine} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-life-ring"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>Custom Support</h3>
                                    <p>Ready to assist at any stage of your solution's journey, ensuring a successful and satisfactory outcome.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Services;