import React from 'react';
import MainBanner from '../PageBanners/MainBannerNrtg';
import About from '../Common/About';
import Ctr from '../Common/Ctr';
import Services from '../Common/Services';
import WhyWeDifferent from '../Common/WhyWeDifferent';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class Home extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <MainBanner />
                
                {/* About Area */}
                <About />

                {/* Why We Are Different */}
                <WhyWeDifferent />

                {/* CTR Area */}
                <Ctr />

                {/* Services Area */}
                <Services />

                {/* Contact Area */}
                <Contact />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </>
        );
    }
}
 
export default Home;