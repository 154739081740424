import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
 
const Ctr = () => {
    return (
        <section className="ctr-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title mb-0">
                            <h4>Looking for services not listed?</h4>
                            <h2>Reach out to us today!</h2>
                            <p>We thrive on providing tailored solutions crafted specifically to meet your needs.</p>
                            <AnchorLink href="#contact" className="btn btn-primary">Contact</AnchorLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
 
export default Ctr;