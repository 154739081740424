import React from 'react';
import imgSpeedFlexibility from '../../assets/images/different-speed-flexibility-2.jpg';
import imgTechStack from '../../assets/images/different-tech-stack-1.jpg';
import imgExperienceStaff from '../../assets/images/different-experienced-staff-1.jpg';
 
class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render(){
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Why We Are<span> Different</span></h2>
                        <p>At the heart of our vibrant collective beats a unified passion for groundbreaking technology and its transformative impact on the world. We are not just problem-solvers; we are pioneers in the digital frontier, dedicated to shaping a future where technology and human ingenuity converge to create unprecedented possibilities.</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'speed-flexibility')} 
                                    className="tablinks checked" 
                                    name="tabset" 
                                    id="tab1" 
                                    aria-controls="speed-flexibility"  
                                    htmlFor="tab1"
                                >
                                    Speed and Flexibility
                                </label>
                                 {/* <!-- Tab 2 --> */}
                                 <label 
                                    onClick={(e) => this.openTabSection(e, 'experienced-staff')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab3" 
                                    aria-controls="experienced-staff"  
                                    htmlFor="tab3"
                                >
                                    Experienced Staff
                                </label>
                                {/* <!-- Tab 3 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'profeesional-work')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab2" 
                                    aria-controls="profeesional-work"  
                                    htmlFor="tab2"
                                >
                                    Technology Stack
                                </label>
                               
                                <div className="tab-panels">
                                    <section id="speed-flexibility" className="tab-panel" style={{display: "block"}}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Speed and Flexibility</h4>
                                                    <p>We harness the boundless potential of cloud technologies, coupled with an agile development methodology, to expedite solution delivery. Our approach ensures that innovation keeps pace with your needs, providing rapid, adaptable, and cutting-edge responses to the ever-evolving technological landscape.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Direct, practical industry experience working with numerous mid-sized enterprises</li>
                                                        <li><i className="fa fa-check"></i>Hands on experience launching new products and brands</li>
                                                        <li><i className="fa fa-check"></i>Proficient across the spectrum of Cloud offerings, from Infrastructure as a Service (IaaS) to Platform as a Service (PaaS)</li>
                                                        <li><i className="fa fa-check"></i>Versatile integration expertise across an extensive array of systems in diverse industries</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgSpeedFlexibility} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section id="experienced-staff" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Experienced staff</h4>
                                                    <p>Our team boasts a wealth of experience across various industries, granting us a competitive edge thanks to our diverse and expansive portfolio.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>eCommerce and multi-channeled Product Sales</li>
                                                        <li><i className="fa fa-check"></i>Power Generation and Utility</li>
                                                        <li><i className="fa fa-check"></i>Warehousing and Manufacturing</li>
                                                        <li><i className="fa fa-check"></i>Bitcoin Mine Operations and Trading</li>
                                                        <li><i className="fa fa-check"></i>Commercial and Commodities Trading</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgExperienceStaff} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section id="profeesional-work" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Technology Stack</h4>
                                                    <p>We utilize a cutting-edge technology stack, ensuring that our solutions seamlessly integrate into your cloud infrastructure or on-premises data center. This approach provides the flexibility and control necessary to align with your specific operational requirements and security standards, enabling a tailored fit for your enterprise's unique ecosystem.</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Transactional data storage compatible with leading database technologies</li>
                                                        <li><i className="fa fa-check"></i>Backend architecture crafted for deployment across diverse operating systems</li>
                                                        <li><i className="fa fa-check"></i>User interface development utilizing technologies that enable dynamic web experiences</li>
                                                        <li><i className="fa fa-check"></i>Analytical data storage solutions optimized for large-scale data and cloud-based environments</li>
                                                        <li><i className="fa fa-check"></i>Comprehensive data and integration orchestration capable of handling batch processes, event-driven activities, and real-time operations</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTechStack} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhyWeDifferent;