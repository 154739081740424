import React from 'react';
import logo from '../../assets/images/logo_transparent_225.png';

class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <img src={logo} alt="logo-img"/>
                            <p>Copyright NextReach Technology Group LLC <i className="fa fa-copyright"></i> 2023 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;